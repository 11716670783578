import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import { getEntityRange, getSelectionEntity, getSelectionText } from 'draftjs-utils';
import linkifyIt from 'linkify-it';

import { LinkIcon } from '../../icons/LinkIcon';
import { UnlinkIcon } from '../../icons/UnlinkIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';
import useToolModal from '../common/useToolModal';
import { AddLinkModal, Props as AddLinkModalProps } from './AddLinkModal';

const getExistingLinkSelection = (editorState: EditorState, entityKey: string): SelectionState => {
  const entityRange = getEntityRange(editorState, entityKey);
  const selection = editorState.getSelection();
  const isBackward = selection.getIsBackward();

  if (isBackward) {
    return selection.merge({
      anchorOffset: entityRange.end,
      focusOffset: entityRange.start,
    });
  }

  return selection.merge({
    anchorOffset: entityRange.start,
    focusOffset: entityRange.end,
  });
};

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function Link({ onChange, editorState }: Props) {
  const intl = useIntl();
  const referenceElementRef = useRef(null);
  const popperElementRef = useRef(null);
  const { styles, attributes, closeModal, toggleModal, isModalOpen } = useToolModal(
    referenceElementRef,
    popperElementRef
  );

  const currentEntityKey = useMemo(
    () => (editorState ? getSelectionEntity(editorState) : undefined),
    [editorState]
  );

  const selectionText = useMemo(() => {
    return getSelectionText(editorState);
  }, [editorState]);

  const link = useMemo(() => {
    const contentState = editorState.getCurrentContent();
    const entity = currentEntityKey ? contentState.getEntity(currentEntityKey) : undefined;

    if (entity?.getType() === 'LINK') {
      const entityRange = currentEntityKey
        ? getEntityRange(editorState, currentEntityKey)
        : undefined;

      return {
        title: entityRange?.text,
        url: entity.getData().url as string,
        target: entity.getData().target as string,
      };
    }

    return undefined;
  }, [currentEntityKey, editorState]);

  const removeLink = useCallback(() => {
    if (currentEntityKey) {
      onChange(
        RichUtils.toggleLink(
          editorState,
          getExistingLinkSelection(editorState, currentEntityKey),
          null
        )
      );
    }
  }, [currentEntityKey, editorState, onChange]);

  const addLink = useCallback<AddLinkModalProps['onSubmit']>(
    ({ title, url }) => {
      const linkify = linkifyIt();
      const links = linkify.match(url);

      const entityKey = editorState
        .getCurrentContent()
        .createEntity('LINK', 'MUTABLE', {
          url: (links && links[0] && links[0].url) || url,
          target: '_blank',
        })
        .getLastCreatedEntityKey();

      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        currentEntityKey
          ? getExistingLinkSelection(editorState, currentEntityKey)
          : editorState.getSelection(),
        title,
        editorState.getCurrentInlineStyle(),
        entityKey
      );

      onChange(EditorState.push(editorState, contentState, 'insert-characters'));
      closeModal();
    },
    [closeModal, currentEntityKey, editorState, onChange]
  );

  return (
    <ToolGroup aria-label="rdw-link-control" ref={referenceElementRef}>
      <ToolButton
        onClick={toggleModal}
        aria-haspopup="dialog"
        aria-pressed={isModalOpen}
        title={intl.formatMessage({ id: 'wysiwygEditor.link.link' })}
      >
        <LinkIcon />
      </ToolButton>

      <ToolButton
        disabled={!link}
        onClick={removeLink}
        title={intl.formatMessage({ id: 'wysiwygEditor.link.unlink' })}
      >
        <UnlinkIcon />
      </ToolButton>

      <AddLinkModal
        ref={popperElementRef}
        style={styles.popper}
        {...attributes.popper}
        isOpen={isModalOpen}
        onSubmit={addLink}
        onCancel={closeModal}
        initialValues={{
          title: link?.title || selectionText || '',
          url: link?.url || '',
          openInNewWindow: link?.target === '_blank',
        }}
      />
    </ToolGroup>
  );
}
