import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { Form, FormProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { composeValidators, isUrl, required } from 'helpers/validators';
import { Field, Label } from 'components/Forms';
import GenericButton from 'components/GenericButton/GenericButton';
import GenericInput from 'components/GenericInput/GenericInput';
import { FieldSpacer } from 'components/Layout';

import { ToolModal, ToolModalButtons } from '../common/ToolModal';

interface Values {
  title: string;
  url: string;
  openInNewWindow: boolean;
}

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  isOpen: boolean;
  onSubmit: FormProps<Values>['onSubmit'];
  onCancel: () => void;
  initialValues: FormProps<Values>['initialValues'];
}

export const AddLinkModal = React.forwardRef<HTMLDivElement, Props>(
  ({ isOpen, onSubmit, onCancel, initialValues, ...rest }, ref) =>
    ReactDOM.createPortal(
      <Form<Values>
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <ToolModal role="dialog" aria-hidden={!isOpen} {...rest} ref={ref}>
            <form onSubmit={handleSubmit}>
              <Field
                name="title"
                validate={required}
                renderLabel={({ input }) => (
                  <Label text="wysiwygEditor.link.linkTitle" elementId={input.id} isRequired />
                )}
                renderControl={({ input, meta }) => (
                  <GenericInput
                    {...input}
                    hasError={meta.showError}
                    hasFullWidth
                    hasNoBackground
                    isSmall
                  />
                )}
              />

              <FieldSpacer />

              <Field
                name="url"
                validate={composeValidators(required, isUrl)}
                renderLabel={({ input }) => (
                  <Label text="wysiwygEditor.link.linkTarget" elementId={input.id} isRequired />
                )}
                renderControl={({ input, meta }) => (
                  <GenericInput
                    {...input}
                    hasError={meta.showError}
                    hasFullWidth
                    hasNoBackground
                    isSmall
                  />
                )}
              />

              <ToolModalButtons>
                <GenericButton type="submit" disabled={submitting} hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.add" />
                </GenericButton>

                <GenericButton type="button" onClick={onCancel} isTransparent hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.cancel" />
                </GenericButton>
              </ToolModalButtons>
            </form>
          </ToolModal>
        )}
      />,
      document.body
    )
);
