import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function AlignCenterIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M11.556 14.887H3.388a.326.326 0 010-.652h8.167a.326.326 0 010 .652zm3.062-4.725H.326a.326.326 0 010-.653h14.292a.326.326 0 010 .653zm-3.062-4.727H3.388a.326.326 0 010-.652h8.167a.326.326 0 010 .652zM14.618.709H.326a.326.326 0 010-.652h14.292a.326.326 0 010 .652z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
