import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function BoldIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 12 13" {...props}>
      <path
        d="M6.236 0c1.652 0 2.94.298 3.866.893.925.595 1.388 1.485 1.388 2.669a2.67 2.67 0 01-.516 1.61c-.343.474-.844.83-1.499 1.068.843.167 1.474.523 1.895 1.071.419.55.63 1.183.63 1.903 0 1.245-.444 2.187-1.33 2.825-.886.641-2.144.961-3.769.961H0v-2.167h1.494V2.167H0V0h6.236zM4.308 5.446h2.024c.752 0 1.33-.143 1.734-.43.405-.285.608-.701.608-1.25 0-.6-.204-1.044-.612-1.33-.408-.286-1.016-.427-1.826-.427H4.308v3.437zm0 1.804V11h2.593c.747 0 1.314-.152 1.707-.452.39-.3.588-.745.588-1.334 0-.636-.168-1.124-.5-1.46-.336-.335-.864-.504-1.582-.504H4.308z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
