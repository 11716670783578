import React from 'react';

import { composeValidators, mustBeInteger, mustBePositiveNumber } from 'helpers/validators';
import { Field, Label } from 'components/Forms';
import GenericInput from 'components/GenericInput/GenericInput';
import { Column, Grid, Row } from 'components/Layout';

const numberValidator = composeValidators(mustBeInteger, mustBePositiveNumber);

function validateNumber(value: unknown) {
  if (value == null) {
    return undefined;
  }

  return numberValidator(value);
}

export function DimensionsFields() {
  return (
    <Grid>
      <Row>
        <Column xsUp={6}>
          <Field<number>
            name="height"
            validate={validateNumber}
            renderLabel={({ input }) => (
              <Label text="wysiwygEditor.image.height" elementId={input.id} />
            )}
            renderControl={({ input, meta }) => (
              <GenericInput
                {...input}
                hasError={meta.showError}
                placeholder="wysiwygEditor.image.autoPlaceholder"
                hasFullWidth
                hasNoBackground
                isSmall
              />
            )}
          />
        </Column>

        <Column xsUp={6}>
          <Field<number>
            name="width"
            validate={validateNumber}
            renderLabel={({ input }) => (
              <Label text="wysiwygEditor.image.width" elementId={input.id} />
            )}
            renderControl={({ input, meta }) => (
              <GenericInput
                {...input}
                hasError={meta.showError}
                placeholder="wysiwygEditor.image.autoPlaceholder"
                hasFullWidth
                hasNoBackground
                isSmall
              />
            )}
          />
        </Column>
      </Row>
    </Grid>
  );
}
