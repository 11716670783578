import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { EditorState, Modifier } from 'draft-js';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import { forEach, reduce } from 'lodash';

import inlineStyles from '../../constants/inlineStyles';
import { EraserIcon } from '../../icons/EraserIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function Remove({ onChange, editorState }: Props) {
  const intl = useIntl();

  const removeInlineStyles = useCallback(() => {
    let newContentState = reduce(
      inlineStyles,
      (result, style) => Modifier.removeInlineStyle(result, editorState.getSelection(), style),
      editorState.getCurrentContent()
    );

    const customStyles = getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']);

    forEach(customStyles, (key, value) => {
      if (value) {
        newContentState = Modifier.removeInlineStyle(
          newContentState,
          editorState.getSelection(),
          key
        );
      }
    });

    onChange(EditorState.push(editorState, newContentState, 'change-inline-style'));
  }, [onChange, editorState]);

  return (
    <ToolGroup aria-label="rdw-remove-control">
      <ToolButton
        onClick={removeInlineStyles}
        title={intl.formatMessage({ id: 'wysiwygEditor.remove' })}
      >
        <EraserIcon />
      </ToolButton>
    </ToolGroup>
  );
}
