import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function UnlinkIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <g fillRule="evenodd">
        <path d="M13.956 1.037a3.55 3.55 0 00-5.014 0L6.436 3.544a.545.545 0 10.77.77l2.508-2.506a2.438 2.438 0 011.735-.715A2.438 2.438 0 0113.9 3.544c0 .658-.254 1.274-.715 1.735L9.907 8.558a2.458 2.458 0 01-3.472 0 .545.545 0 10-.771.771 3.534 3.534 0 002.507 1.037c.908 0 1.816-.346 2.507-1.037l3.278-3.278a3.52 3.52 0 001.035-2.507c0-.95-.367-1.84-1.035-2.507z" />
        <path d="M7.4 11.065l-2.122 2.12a2.437 2.437 0 01-1.735.716 2.437 2.437 0 01-1.736-.715 2.457 2.457 0 010-3.471l3.086-3.086a2.438 2.438 0 011.735-.715c.658 0 1.275.254 1.736.715a.545.545 0 10.771-.771 3.55 3.55 0 00-5.014 0L1.036 8.944A3.52 3.52 0 000 11.45c0 .95.367 1.84 1.035 2.507a3.52 3.52 0 002.506 1.035c.95 0 1.84-.368 2.507-1.035l2.122-2.121a.545.545 0 00-.771-.771zm1.874.937a.546.546 0 00-.546.545v1.637a.546.546 0 001.091 0v-1.637a.545.545 0 00-.545-.545zm1.956-.386a.545.545 0 10-.772.772l1.157 1.156a.543.543 0 00.771 0 .545.545 0 000-.77l-1.156-1.158zm1.307-1.796H10.9a.546.546 0 000 1.091h1.637a.546.546 0 000-1.09zM4.91 3.547a.546.546 0 00.545-.545V1.366a.546.546 0 00-1.09 0v1.636c0 .301.244.545.545.545zm-2.022.386a.543.543 0 00.771 0 .545.545 0 000-.771L2.502 2.005a.545.545 0 10-.771.77l1.157 1.158zM1.628 5.73h1.636a.546.546 0 000-1.092H1.628a.546.546 0 000 1.091z" />
      </g>
    </SvgIcon>
  );
}
