import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function AlignLeftIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M8.493 14.887H.326a.326.326 0 010-.652h8.167a.326.326 0 010 .652zm6.125-4.725H.326a.326.326 0 010-.653h14.292a.326.326 0 010 .653zM8.493 5.435H.326a.326.326 0 010-.652h8.167a.326.326 0 010 .652zM14.618.709H.326a.326.326 0 010-.652h14.292a.326.326 0 010 .652z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
