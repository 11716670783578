import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { Form, FormProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { composeValidators, isUrl, required } from 'helpers/validators';
import { Field, Label } from 'components/Forms';
import GenericButton from 'components/GenericButton/GenericButton';
import GenericInput from 'components/GenericInput/GenericInput';
import { FieldSpacer } from 'components/Layout';

import { DimensionsFields } from '../common/DimensionsFields';
import { ToolModal, ToolModalButtons } from '../common/ToolModal';

interface Values {
  src: string;
  height: number;
  width: number;
}

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  isOpen: boolean;
  onSubmit: FormProps<Values>['onSubmit'];
  onCancel: () => void;
}

export const AddImageModal = React.forwardRef<HTMLDivElement, Props>(
  ({ isOpen, onSubmit, onCancel, ...rest }, ref) => {
    return ReactDOM.createPortal(
      <ToolModal role="dialog" aria-hidden={!isOpen} {...rest} ref={ref}>
        <Form<Values>
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field<string>
                name="src"
                validate={composeValidators(required, isUrl)}
                renderLabel={({ input }) => (
                  <Label text="wysiwygEditor.image.src" elementId={input.id} isRequired />
                )}
                renderControl={({ input, meta }) => (
                  <GenericInput
                    {...input}
                    hasError={meta.showError}
                    hasFullWidth
                    hasNoBackground
                    isSmall
                  />
                )}
              />

              <FieldSpacer />

              <DimensionsFields />

              <ToolModalButtons>
                <GenericButton type="submit" disabled={submitting} hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.add" />
                </GenericButton>

                <GenericButton type="button" onClick={onCancel} isTransparent hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.cancel" />
                </GenericButton>
              </ToolModalButtons>
            </form>
          )}
        />
      </ToolModal>,
      document.body
    );
  }
);
