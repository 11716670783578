import 'draft-js/dist/Draft.css';

import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  CompositeDecorator,
  DraftStyleMap,
  Editor,
  EditorProps,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js';
import { blockRenderMap, getSelectedBlocksType, handleNewLine } from 'draftjs-utils';
import styled from 'styled-components';

import { textarea, TextareaProps } from '../../styled';
import { FontFamily } from './constants/fontFamily';
import { InlineStyle } from './constants/inlineStyles';
import { linkDecorator } from './decorators/Link';
import { ImageBlockProps, ImageComponent } from './renderers/Image';
import { Toolbar, ToolbarConfig } from './Toolbar';

const blockStyleFn: EditorProps['blockStyleFn'] = block => {
  const blockAlignment = block.getData().get('text-align');
  if (blockAlignment) {
    return `aligned-block ${blockAlignment}-aligned-block`;
  }

  return '';
};

export const customStyleMap: DraftStyleMap = {
  [InlineStyle.Code]: {
    fontFamily: 'monospace',
    wordWrap: 'break-word',
    background: '#f1f1f1',
    borderRadius: 3,
    padding: '1px 3px',
  },
  [InlineStyle.Superscript]: {
    fontSize: '0.6em',
    verticalAlign: 'super',
  },
  [InlineStyle.Subscript]: {
    fontSize: '0.6em',
    verticalAlign: 'sub',
  },
  [`fontfamily-${FontFamily.Lato}`]: {
    fontFamily: FontFamily.Lato,
  },
  [`fontfamily-${FontFamily.Montserrat}`]: {
    fontFamily: FontFamily.Montserrat,
  },
  [`fontfamily-${FontFamily.OpenSans}`]: {
    fontFamily: 'Open Sans',
  },
  [`fontfamily-${FontFamily.Roboto}`]: {
    fontFamily: FontFamily.Roboto,
  },
  [`fontfamily-${FontFamily.Poppins}`]: {
    fontFamily: FontFamily.Poppins,
  },
};

const Wrapper = styled.div`
  box-sizing: content-box;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }

  .aligned-block > div {
    display: inline-block;
  }

  .right-aligned-block {
    text-align: right;
  }

  .left-aligned-block {
    text-align: left;
  }

  .center-aligned-block {
    text-align: center;
  }

  .justify-aligned-block {
    text-align: justify;
  }

  .DraftEditor-editorContainer {
    border: none;
  }

  .public-DraftStyleDefault-block {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const InnerWrapper = styled.div<TextareaProps>`
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  ${textarea};
  color: initial;

  .DraftEditor-root {
    height: 100%;
  }

  blockquote {
    padding-left: 5px;
    border-left: 5px solid #f1f1f1;
  }

  pre {
    padding: 1px 10px;
    background: #f1f1f1;
    border-radius: 3px;
  }
`;

const compositeDecorator = new CompositeDecorator([linkDecorator]);

const isInteractiveElement = (tagName: string): boolean =>
  ['BUTTON', 'INPUT', 'LABEL', 'TEXTAREA'].includes(tagName);

export interface Props extends TextareaProps {
  onChange?(editorState: EditorState): void;
  editorState: EditorState;
  toolbarConfig?: Partial<ToolbarConfig>;
  onFocus?(event: React.FocusEvent<HTMLDivElement>): void;
  onBlur?(event: React.FocusEvent<HTMLDivElement>): void;
  readOnly?: boolean;
  placeholder?: string;
  ariaLabelledBy?: string;
}

export default function WysiwygEditor({
  onChange,
  editorState,
  toolbarConfig,
  onFocus,
  onBlur,
  readOnly,
  disabled,
  placeholder,
  ariaLabelledBy,
  ...textareaProps
}: Props) {
  const editorRef = useRef<Editor>(null);

  const [editorFocused, setEditorFocused] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [editorReadOnly, setEditorReadOnly] = useState(false);

  const setEditorFocusOn = useCallback(() => setEditorFocused(true), []);

  const setEditorFocusOff = useCallback(() => setEditorFocused(false), []);

  const decoratedEditorState = useMemo(
    () =>
      EditorState.set(editorState, {
        decorator: compositeDecorator,
      }),
    [editorState]
  );

  const handleEditorFocus = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      setEditorFocused(true);

      if (!inputFocused) {
        onFocus?.(event);
      }

      setInputFocused(false);
    },
    [inputFocused, onFocus]
  );

  const handleToolbarFocus = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      if (!editorFocused) {
        onFocus?.(event);
      }

      setEditorFocused(false);
    },
    [editorFocused, onFocus]
  );

  const handleWrapperBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      if (!isInteractiveElement(event.target.tagName) && !inputFocused) {
        setEditorFocused(false);
        onBlur?.(event);
      }
    },
    [inputFocused, onBlur]
  );

  const handleChange = useCallback<EditorProps['onChange']>(
    newEditorState => {
      if (
        onChange &&
        !readOnly &&
        !(
          getSelectedBlocksType(newEditorState) === 'atomic' &&
          newEditorState.getSelection().isCollapsed
        )
      ) {
        onChange(newEditorState);
      }
    },
    [onChange, readOnly]
  );

  const focusEditor = useCallback(() => {
    setTimeout(() => editorRef.current?.focus());
  }, []);

  const handleKeyCommand = useCallback<Required<EditorProps>['handleKeyCommand']>(
    (command, currentEditorState) => {
      const newState = RichUtils.handleKeyCommand(currentEditorState, command);
      if (newState) {
        handleChange(newState);
        return 'handled';
      }

      return 'not-handled';
    },
    [handleChange]
  );

  const handleReturn = useCallback<Required<EditorProps>['handleReturn']>(
    (event, currentEditorState) => {
      const newState = handleNewLine(currentEditorState, event);
      if (newState) {
        handleChange(newState);
        return 'handled';
      }

      return 'not-handled';
    },
    [handleChange]
  );

  const handleReadOnlyToggle = useCallback((value: boolean) => {
    setEditorReadOnly(value);
  }, []);

  const preventDefault = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isInteractiveElement((event.target as HTMLElement).tagName)) {
      setInputFocused(true);
    } else {
      event.preventDefault();
    }
  }, []);

  return (
    <Wrapper data-test="wysiwyg-editor" onBlur={handleWrapperBlur}>
      {!readOnly && !disabled && (
        <Toolbar
          visible={editorFocused || inputFocused}
          editorState={decoratedEditorState}
          onEditorStateChange={handleChange}
          onMouseDown={preventDefault}
          onFocus={handleToolbarFocus}
          config={toolbarConfig}
        />
      )}

      <InnerWrapper
        onClick={focusEditor}
        onFocus={handleEditorFocus}
        onBlur={setEditorFocusOff}
        onMouseDown={setEditorFocusOn}
        readOnly={readOnly}
        disabled={disabled}
        {...textareaProps}
      >
        <Editor
          ref={editorRef}
          ariaLabelledBy={ariaLabelledBy}
          keyBindingFn={getDefaultKeyBinding}
          editorState={decoratedEditorState}
          onChange={handleChange}
          blockStyleFn={blockStyleFn}
          customStyleMap={customStyleMap}
          handleReturn={handleReturn}
          handleKeyCommand={handleKeyCommand}
          blockRenderMap={blockRenderMap}
          blockRendererFn={contentBlock => {
            if (contentBlock.getType() === 'atomic') {
              const contentState = editorState.getCurrentContent();
              const entityKey = contentBlock.getEntityAt(0);

              const entity = contentState.getEntity(entityKey);
              if (entity && entity.getType() === 'IMAGE') {
                const props: ImageBlockProps = {
                  readOnly: Boolean(disabled || readOnly),
                  editorState,
                  onChange: handleChange,
                  onToggleEditorReadOnlyState: handleReadOnlyToggle,
                };

                return {
                  component: ImageComponent,
                  editable: false,
                  props,
                };
              }
            }

            return undefined;
          }}
          placeholder={placeholder}
          readOnly={disabled || readOnly || editorReadOnly}
          spellCheck
        />
      </InnerWrapper>
    </Wrapper>
  );
}
