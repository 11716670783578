import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function RedoIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 14 17" {...props}>
      <path
        d="M6.5 14A4.5 4.5 0 012 9.5 4.5 4.5 0 016.5 5v3l5-4-5-4v3A6.5 6.5 0 000 9.5C0 13 3 16 6.5 16A6.5 6.5 0 0013 9.5h-2A4.5 4.5 0 016.5 14z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
