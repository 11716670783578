import React, { useCallback, useEffect, useState } from 'react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import { forEach } from 'lodash';

import fontFamilies from '../../constants/fontFamily';
import { ToolGroup } from '../common/ToolGroup';
import Dropdown from './Dropdown';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function toggleCustomInlineStyle(
  editorState: EditorState,
  styleType: string,
  style: string
) {
  const selection = editorState.getSelection();
  let nextContentState = editorState.getCurrentContent();
  const customStyles = getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']);

  forEach(customStyles, (key, value) => {
    if (value) {
      nextContentState = Modifier.removeInlineStyle(
        nextContentState,
        editorState.getSelection(),
        key
      );
    }
  });

  let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
  const currentStyle = editorState.getCurrentInlineStyle();
  if (selection.isCollapsed()) {
    nextEditorState = currentStyle.reduce((state, s) => {
      if (state && s) {
        return RichUtils.toggleInlineStyle(state, s);
      }

      return editorState;
    }, nextEditorState);
  }

  if (styleType === 'SUPERSCRIPT' || styleType === 'SUBSCRIPT') {
    if (!currentStyle.has(style)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
    }
  } else if (!currentStyle.has(`${styleType}-${style}`)) {
    nextEditorState = RichUtils.toggleInlineStyle(
      nextEditorState,
      `${styleType.toLowerCase()}-${style}`
    );
  }

  return nextEditorState;
}

export function FontFamily({ onChange, editorState }: Props) {
  const [expanded, setExpanded] = useState(false);
  const [currentFontFamily, setCurrentFontFamily] = useState<string>('');

  useEffect(() => {
    if (editorState) {
      const style = getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']);

      setCurrentFontFamily(style.FONTFAMILY?.split('-')[1] || '');
    }
  }, [editorState]);

  const toggleFontFamily = useCallback(
    (fontFamily: string) => {
      setExpanded(!expanded);
      const newState = toggleCustomInlineStyle(editorState, 'fontFamily', fontFamily);

      if (newState) {
        onChange(newState);
      }
    },
    [editorState, expanded, onChange]
  );

  return (
    <ToolGroup aria-label="rdw-textalign-control">
      <Dropdown
        expanded={expanded}
        onToggleEvent={() => setExpanded(!expanded)}
        onCollapseEvent={() => setExpanded(false)}
        currentFontFamily={currentFontFamily}
        options={fontFamilies}
        onChange={toggleFontFamily}
      />
    </ToolGroup>
  );
}
