import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function IndentIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 16 14" {...props}>
      <path
        d="M5.72 3.21H17v1.2H5.72zM0 .02h17v1.2H0zm0 12.76h17v1.2H0zM5.72 9.6H17v1.2H5.72zm0-3.19H17v1.2H5.72zM.19 9.5L2.52 7 .19 4.5z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
