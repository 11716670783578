import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function MonospaceIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 13 15" {...props}>
      <path
        d="M1.02 2.9c.19 1.23.37 1.5.37 2.73C1.4 6.38 0 7.03 0 7.03v.94s1.4.65 1.4 1.4c0 1.22-.2 1.5-.38 2.72C.74 14.06 1.76 15 2.7 15h1.95v-1.88s-1.67.2-1.67-.93c0-.85.19-.85.37-2.72.1-.85-.46-1.5-1.02-1.97.56-.47 1.12-1.03 1.02-1.88-.28-1.87-.37-1.87-.37-2.71 0-1.13 1.67-1.03 1.67-1.03V0H2.7C1.67 0 .74.94 1.02 2.9zm10.96 0c-.19 1.23-.37 1.5-.37 2.73 0 .75 1.39 1.4 1.39 1.4v.94s-1.4.65-1.4 1.4c0 1.22.2 1.5.38 2.72.28 1.97-.74 2.91-1.67 2.91H8.36v-1.88s1.67.2 1.67-.93c0-.85-.19-.85-.37-2.72-.1-.85.46-1.5 1.02-1.97-.56-.47-1.12-1.03-1.02-1.88.18-1.87.37-1.87.37-2.71 0-1.13-1.67-1.04-1.67-1.04V0h1.95c1.02 0 1.95.94 1.67 2.9z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
