import React, { forwardRef, HTMLAttributes } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';
import { useGenericModalContext } from 'components/GenericModal/GenericModalContext';

export const ToolModalButtons = styled.div`
  ${flexCenter()};
  margin-top: ${rem(25)};
  & > *:not(:first-child) {
    margin-left: ${rem(20)};
  }
`;

const Wrapper = styled.div<{ zIndex: number }>`
  z-index: ${({ zIndex }) => zIndex};
  border: 1px solid ${({ theme }) => theme.colors.greyLighten};
  border-radius: ${rem(2)};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 3px 3px 5px ${({ theme }) => theme.colors.grey};

  &[aria-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }
`;

const ToolModalBase = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { isInsideModal } = useGenericModalContext();

  return (
    <Wrapper {...props} zIndex={isInsideModal ? misc.modalZIndex + 1 : misc.zIndex1} ref={ref} />
  );
});

export const ToolModal = styled(ToolModalBase)`
  width: ${rem(260)};
  padding: ${rem(16)};
`;

export const FloatingToolModal = styled(ToolModalBase)`
  padding: ${rem(8)};
`;
