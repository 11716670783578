import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function SubscriptIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="-0.5 -3 18 18" {...props}>
      <path d="M11.87 11.65H9.06l-3.2-3.7-3.11 3.7H0l4.52-5.07L.22 1.8h2.77l3 3.47L9 1.8h2.65L7.31 6.58l4.56 5.07zm1.72 2.05l1.83-1.36a3.92 3.92 0 001.26-1.22c.2-.37.3-.77.3-1.2 0-.7-.23-1.26-.7-1.7a2.67 2.67 0 00-1.88-.64c-.74 0-1.34.22-1.78.65-.45.44-.67 1.1-.67 1.97h1.44c0-.52.09-.88.28-1.08.18-.2.44-.3.77-.3a1 1 0 011.06 1.06c0 .3-.09.58-.26.82-.18.25-.67.66-1.48 1.24-.7.5-1.63.97-1.91 1.42l.01 1.64H17v-1.3h-3.41z" />
    </SvgIcon>
  );
}
