import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function EraserIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8.1 14l6.4-7.2c.6-.7.6-1.8-.1-2.5l-2.7-2.7c-.3-.4-.8-.6-1.3-.6H8.6a2 2 0 00-1.4.6L.5 9.2c-.6.7-.6 1.9.1 2.5l2.7 2.7c.3.4.8.6 1.3.6H16v-1H8.1zm-1.3-.1s0-.1 0 0l-2.7-2.7c-.4-.4-.4-.9 0-1.3L7.5 6h-1l-3 3.3c-.6.7-.6 1.7.1 2.4L5.9 14H4.6l-.6-.2L1.2 11a.8.8 0 010-1.1L4.7 6h1.8L10 2h1L7.5 6l3.1 3.7-3.5 4-.3.2z" />
    </SvgIcon>
  );
}
