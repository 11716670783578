import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function ListOrderedIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path
        d="M4.2 1.47h8.15c.34 0 .61-.33.61-.72 0-.4-.27-.72-.6-.72H4.2c-.34 0-.6.32-.6.72 0 .4.26.72.6.72zm8.15 4.31H4.2c-.34 0-.6.33-.6.72 0 .4.26.72.6.72h8.15c.34 0 .61-.32.61-.72 0-.4-.27-.72-.6-.72zm0 5.76H4.2c-.34 0-.6.32-.6.72 0 .4.26.72.6.72h8.15c.34 0 .61-.32.61-.72 0-.4-.27-.72-.6-.72zM.77 1.24v1.81c0 .2.13.34.31.34s.32-.15.32-.34V.35c0-.18-.14-.33-.3-.33C.94.02.86.13.84.17L.57.63a.47.47 0 00-.1.27c0 .2.13.35.29.36zM.35 8.2h1.2c.16 0 .28-.16.28-.36 0-.2-.12-.36-.28-.36H.68c0-.11.21-.29.38-.43.33-.28.75-.62.75-1.23 0-.57-.38-1-.87-1-.48 0-.84.39-.84.9 0 .3.16.4.3.4.2 0 .33-.17.33-.34 0-.1.02-.23.2-.23.24 0 .25.25.25.28 0 .23-.25.45-.5.65-.3.26-.64.55-.64 1v.38c0 .2.16.34.31.34zm1.42 2.35c0-.59-.3-.93-.81-.93-.68 0-.86.57-.86.87 0 .35.22.4.32.4.18 0 .3-.15.3-.37 0-.09.03-.18.23-.18.14 0 .2.02.2.26 0 .24-.04.27-.21.27-.17 0-.3.15-.3.35 0 .2.13.35.3.35.22 0 .27.1.27.28v.08c0 .3-.1.35-.28.35-.25 0-.27-.15-.27-.2 0-.17-.1-.35-.31-.35-.2 0-.3.14-.3.38 0 .43.3.89.89.89.56 0 .9-.4.9-1.07v-.08a1 1 0 00-.22-.66c.1-.17.15-.38.15-.64z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
