import React, { useCallback, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { usePopper } from 'react-popper';

export default function useToolModal(
  referenceElementRef: React.RefObject<HTMLElement>,
  popperElementRef: React.RefObject<HTMLElement>,
  offset?: [number, number],
  onOutsideClickCallback?: () => void,
  onOutsideClickRefs?: React.RefObject<HTMLElement>[]
) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const toggleModal = useCallback(() => setIsModalOpen(prev => !prev), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  useOnclickOutside(
    () => {
      closeModal();
      if (onOutsideClickCallback) onOutsideClickCallback();
    },
    { refs: onOutsideClickRefs || [popperElementRef] }
  );

  const popper = usePopper(referenceElementRef?.current, popperElementRef.current, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [5, 5],
        },
      },
      { name: 'preventOverflow', enabled: false },
      { name: 'hide', enabled: false },
    ],
    placement: 'bottom-start',
  });

  return {
    ...popper,
    isModalOpen,
    openModal,
    toggleModal,
    closeModal,
  };
}
