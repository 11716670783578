import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function OpenLinkIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <g fillRule="evenodd">
        <path d="M14.072 0H8.915a.925.925 0 000 1.849h2.925L6.961 6.727a.918.918 0 00-.27.654c0 .247.095.48.27.654a.918.918 0 00.654.27.918.918 0 00.653-.27l4.88-4.88v2.926a.925.925 0 001.848 0V.924A.925.925 0 0014.072 0z" />
        <path d="M10.623 13.411H1.585V4.372h6.798l1.584-1.584H.792A.792.792 0 000 3.58v10.624c0 .437.355.792.792.792h10.624a.792.792 0 00.792-.792V5.029l-1.585 1.584v6.798z" />
      </g>
    </SvgIcon>
  );
}
