import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function StrikethroughIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 -1 15 15" {...props}>
      <path
        d="M4.04 5.954h6.215a7.412 7.412 0 00-.795-.438 11.907 11.907 0 00-1.447-.557c-1.188-.348-1.966-.711-2.334-1.088-.368-.377-.552-.77-.552-1.181 0-.495.187-.906.56-1.232.38-.331.887-.497 1.523-.497.68 0 1.266.255 1.757.767.295.315.582.891.861 1.73l.117.016.703.05.1-.024c.028-.152.042-.279.042-.38 0-.337-.039-.852-.117-1.544a9.374 9.374 0 00-.176-.995 12.142 12.142 0 00-1.48-.405C8.365.07 7.899.016 7.62.016c-1.45 0-2.545.357-3.287 1.071-.747.72-1.12 1.589-1.12 2.607 0 .511.133 1.04.4 1.586.129.253.27.478.427.674zm4.24 2.16c.575.236.957.436 1.147.599.451.41.677.852.677 1.324 0 .383-.13.745-.393 1.088-.25.338-.59.58-1.02.726a3.416 3.416 0 01-1.163.228 3.11 3.11 0 01-1.104-.186 2.696 2.696 0 01-.878-.48 3.133 3.133 0 01-.67-.794 1.527 1.527 0 01-.104-.227 57.523 57.523 0 00-.188-.473 21.371 21.371 0 00-.251-.599l-.853.017v.371l-.017.313a9.92 9.92 0 000 .573c.011.27.017.709.017 1.316v.11c0 .079.022.14.067.185.083.068.284.147.602.237l1.17.337c.452.13.996.194 1.632.194.686 0 1.252-.059 1.698-.177a4.694 4.694 0 001.28-.557c.401-.259.705-.486.911-.683.268-.276.466-.568.594-.878a4.74 4.74 0 00.343-1.788c0-.298-.02-.557-.058-.776H8.281zm6.634-1.544a.26.26 0 00-.193-.076H.268a.26.26 0 00-.193.076.264.264 0 00-.075.194v.54c0 .079.025.143.075.194a.26.26 0 00.193.076H14.72a.26.26 0 00.193-.076.264.264 0 00.075-.194v-.54a.264.264 0 00-.075-.194z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
