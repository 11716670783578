import React, { useMemo } from 'react';
import { EditorState } from 'draft-js';
import styled from 'styled-components';

import { stripHtmlTags } from 'helpers/strings';
import { flexCenter } from 'helpers/styles/mixins';
import { isNotBlank } from 'helpers/utils';
import GenericLengthCounter from 'components/GenericLengthCounter/GenericLengthCounter';

import Editor, { Props as EditorProps } from './components/WysiwygEditor/Editor';
import { toHTML } from './utils';
import { VerticalSize } from './VerticalSize';

const Wrapper = styled.div`
  ${flexCenter()};
  position: relative;
`;

export interface Props extends Omit<EditorProps, 'editorState'> {
  id?: string;
  maxLength?: number;
  value?: EditorState;
  className?: string;
}

export default function RichTextEditor({
  hasBoldBorderTop = false,
  hasError = false,
  hasVerticalResize = false,
  hasAccent = false,
  disabled = false,
  readOnly = false,
  minVerticalSize = VerticalSize.Medium,
  maxLength,
  className,
  value,
  ...restProps
}: Props) {
  const textValue = useMemo(() => (value ? stripHtmlTags(toHTML(value)) : ''), [value]);

  return (
    <Wrapper className={className}>
      <Editor
        editorState={value || EditorState.createEmpty()}
        hasBoldBorderTop={hasBoldBorderTop}
        hasVerticalResize={hasVerticalResize}
        hasAccent={hasAccent}
        hasError={hasError}
        minVerticalSize={minVerticalSize}
        disabled={disabled}
        readOnly={readOnly}
        {...restProps}
      />

      {maxLength && !disabled && !readOnly && (
        <GenericLengthCounter
          currentLength={isNotBlank(textValue) ? textValue.length : 0}
          maxLength={maxLength}
        />
      )}
    </Wrapper>
  );
}
