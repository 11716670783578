import React from 'react';
import styled from 'styled-components';

interface StyledLiProps {
  active: boolean;
}

const StyledList = styled.li<StyledLiProps>`
  display: flex;
  align-items: center;

  min-height: 25px;
  padding: 0 5px;
  text-transform: capitalize;

  ${({ active }) => active && `background: #f5f5f5;`}

  &:hover {
    background-color: #f1f1f1;
  }
`;

interface Props {
  fontFamily: string;
  active: boolean;
  onClick(fontFamily: string): void;
}

const DropdownOption = ({ fontFamily, active, onClick }: Props) => (
  <StyledList
    active={active}
    onClick={e => {
      e.stopPropagation();
      onClick(fontFamily);
    }}
  >
    {fontFamily}
  </StyledList>
);

export default DropdownOption;
