import React, { useRef } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { rem } from 'polished';
import styled from 'styled-components';

import DropdownOption from './DropdownOption';

export const SelectWrapper = styled.div`
  position: relative;
  height: 30px;
  margin: 0 ${rem(4)} ${rem(6)};
  text-transform: capitalize;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
    background-color: #ffffff;
  }

  &:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
  }
`;

const OptionWrapper = styled.ul`
  position: absolute;
  top: 35px;
  z-index: 100;
  overflow-y: scroll;

  width: 150px;
  max-width: 140px;
  max-height: 250px;
  margin: 0;
  padding: 5px 0;

  background: white;
  border-radius: 2px;
  border: 1px solid #f1f1f1;

  &:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
    background-color: #ffffff;
  }
`;

export const StyledSelectedText = styled.a`
  display: flex;
  align-items: center;

  min-width: 100px;
  height: 100%;
  padding: 0 5px;
`;

export const CaretOpen = styled.div`
  position: absolute;
  top: 35%;
  right: 10%;

  width: 0px;
  height: 0px;

  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
`;

export const CaretClose = styled.div`
  position: absolute;
  top: 35%;
  right: 10%;

  width: 0px;
  height: 0px;

  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
`;

interface Props {
  options: string[];
  expanded: boolean;
  currentFontFamily: string;
  onToggleEvent: () => void;
  onCollapseEvent: () => void;
  onChange(fontFamily: string): void;
}

function Dropdown({
  onToggleEvent,
  onChange,
  onCollapseEvent,
  currentFontFamily,
  expanded,
  options,
}: Props) {
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const optionWrapperRef = useRef<HTMLUListElement>(null);

  useOnclickOutside(() => onCollapseEvent(), { refs: [optionWrapperRef, selectWrapperRef] });

  return (
    <SelectWrapper ref={selectWrapperRef} aria-expanded={expanded} arial-label="rdw-dropdown">
      <StyledSelectedText onClick={onToggleEvent}>
        {currentFontFamily || 'Font'}
        {expanded ? <CaretClose /> : <CaretOpen />}
      </StyledSelectedText>

      {expanded && (
        <OptionWrapper ref={optionWrapperRef} onClick={e => e.stopPropagation()}>
          {options.map(option => (
            <DropdownOption
              key={option}
              active={option === currentFontFamily}
              onClick={onChange}
              fontFamily={option}
            />
          ))}
        </OptionWrapper>
      )}
    </SelectWrapper>
  );
}

export default Dropdown;
