import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function ListUnorderedIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 16 14" {...props}>
      <path
        d="M1.72 3.43c.95 0 1.72-.77 1.72-1.71a1.72 1.72 0 00-3.44 0c0 .94.77 1.7 1.72 1.7zm0-2.63c.51 0 .92.41.92.92a.92.92 0 01-1.84 0A.92.92 0 011.72.8zm0 7.9c.95 0 1.72-.76 1.72-1.7A1.72 1.72 0 000 7c0 .94.77 1.7 1.72 1.7zm0-2.62c.51 0 .92.41.92.91A.92.92 0 01.8 7c0-.5.41-.9.92-.9zm0 7.9c.95 0 1.72-.77 1.72-1.7a1.72 1.72 0 00-3.44 0c0 .93.77 1.7 1.72 1.7zm0-2.62c.51 0 .92.4.92.91a.92.92 0 11-1.84 0 .92.92 0 01.92-.91zm4.02-9.24h9.85a.4.4 0 00.4-.4.4.4 0 00-.4-.4H5.74a.4.4 0 00-.4.4.4.4 0 00.4.4zm0 5.27h9.85a.4.4 0 00.4-.4.4.4 0 00-.4-.4H5.74a.4.4 0 00-.4.4.4.4 0 00.4.4zm0 5.28h9.85a.4.4 0 00.4-.4.4.4 0 00-.4-.4H5.74a.4.4 0 00-.4.4.4.4 0 00.4.4z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
