import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function ImageIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 14" {...props}>
      <g fillRule="evenodd">
        <path d="M14.74 0H.26C.12 0 0 .14 0 .3v13.4c0 .16.12.3.26.3h14.48c.14 0 .26-.14.26-.3V.3c0-.16-.12-.3-.26-.3zm-.26 13.4H.52V.6h13.96v12.78z" />
        <path d="M4.14 6.74c.8 0 1.44-.76 1.44-1.7s-.65-1.7-1.44-1.7c-.8 0-1.44.77-1.44 1.7 0 .94.64 1.7 1.44 1.7zm0-2.78c.5 0 .92.48.92 1.08 0 .6-.41 1.09-.92 1.09s-.92-.49-.92-1.09c0-.6.4-1.08.92-1.08zM1.8 12.17c.06 0 .12-.02.17-.07L6.2 7.73l2.66 3.13a.23.23 0 00.37 0 .34.34 0 000-.43L7.99 8.97l2.37-3.06 2.91 3.14c.1.11.27.1.37-.02a.34.34 0 00-.02-.43l-3.1-3.35a.24.24 0 00-.19-.08.24.24 0 00-.18.1L7.62 8.54 6.4 7.09a.23.23 0 00-.35 0l-4.4 4.55a.34.34 0 00-.02.43.24.24 0 00.19.1z" />
      </g>
    </SvgIcon>
  );
}
