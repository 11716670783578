import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function UndoIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 14 17" {...props}>
      <path
        d="M7 14.9c2.7 0 4.8-2.2 4.8-4.8 0-2.6-2.1-4.8-4.8-4.8v3.2L1.6 4.2 7 0v3.2c3.9 0 7 3 7 6.9S10.9 17 7 17s-7-3.1-7-7h2.2C2.2 12.8 4.3 15 7 15z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
