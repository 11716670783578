import React from 'react';
import { startCase } from 'lodash';
import { rem, size } from 'polished';
import styled from 'styled-components';

const SymbolGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 200px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const SymbolContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(10)};
  ${size(42)}
  margin: 2px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.greyLighten};
`;

interface Props {
  symbols: Record<string, string>;
  onClick: (currency: string) => void;
}
const SymbolsContainer = ({ symbols, onClick }: Props) => (
  <SymbolGrid>
    {Object.keys(symbols).map((symbol: string) => (
      <SymbolContainer
        key={symbol}
        onClick={() => onClick(symbols[symbol])}
        title={startCase(symbol)}
      >
        {symbols[symbol]}
      </SymbolContainer>
    ))}
  </SymbolGrid>
);

export default SymbolsContainer;
