import React, { useMemo } from 'react';
import type { EditorState } from 'draft-js';
import { isBoolean, merge } from 'lodash';
import styled from 'styled-components';

import { misc } from 'helpers/styles/constants';

import {
  Currency,
  FontFamily,
  History,
  Image,
  Inline,
  InlineToolOptions,
  Link,
  List,
  Remove,
  Symbols,
  TextAlign,
} from './controls';

export const Wrapper = styled.div`
  z-index: ${misc.zIndex1};
  position: absolute;
  bottom: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 6px 5px 0;
  font-size: 15px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  background: white;
  user-select: none;

  &[aria-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }
`;

export interface ToolbarConfig {
  inline: boolean | InlineToolOptions;
  list: boolean;
  textAlign: boolean;
  link: boolean;
  remove: boolean;
  history: boolean;
  image: boolean;
  fontFamily: boolean;
  currency: boolean;
  symbols: boolean;
}

const defaultInlineOptions: InlineToolOptions = {
  bold: true,
  italic: true,
  underline: true,
  strikethrough: true,
  code: true,
  superscript: true,
  subscript: true,
};

const defaultConfig: ToolbarConfig = {
  inline: defaultInlineOptions,
  list: true,
  textAlign: true,
  link: true,
  remove: true,
  history: true,
  image: false,
  fontFamily: true,
  currency: true,
  symbols: true,
};

export interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  config?: Partial<ToolbarConfig>;
}

export function Toolbar({ visible, editorState, config, onEditorStateChange, ...rest }: Props) {
  const {
    history: historyConfig,
    inline: inlineConfig,
    link: linkConfig,
    list: listConfig,
    remove: removeConfig,
    textAlign: textAlignConfig,
    image: imageConfig,
    fontFamily: fontFamilyConfig,
    currency: currencyConfig,
    symbols: symbolsConfig,
  } = useMemo(() => merge({}, defaultConfig, config), [config]);

  const controlProps = {
    editorState,
    onChange: onEditorStateChange,
  };

  return (
    <Wrapper role="toolbar" aria-hidden={!visible} {...rest}>
      {inlineConfig && (
        <Inline
          {...controlProps}
          options={isBoolean(inlineConfig) ? defaultInlineOptions : inlineConfig}
        />
      )}
      {listConfig && <List {...controlProps} />}
      {textAlignConfig && <TextAlign {...controlProps} />}
      {linkConfig && <Link {...controlProps} />}
      {imageConfig && <Image {...controlProps} />}
      {removeConfig && <Remove {...controlProps} />}
      {historyConfig && <History {...controlProps} />}
      {currencyConfig && <Currency {...controlProps} />}
      {fontFamilyConfig && <FontFamily {...controlProps} />}
      {symbolsConfig && <Symbols {...controlProps} />}
    </Wrapper>
  );
}
