import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { EditorState } from 'draft-js';
import { getSelectedBlocksMetadata, setBlockData } from 'draftjs-utils';

import { TextAlignment } from '../../constants/textAlignment';
import { AlignCenterIcon } from '../../icons/AlignCenterIcon';
import { AlignJustifyIcon } from '../../icons/AlignJustifyIcon';
import { AlignLeftIcon } from '../../icons/AlignLeftIcon';
import { AlignRightIcon } from '../../icons/AlignRightIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function TextAlign({ onChange, editorState }: Props) {
  const intl = useIntl();

  const currentTextAlignment = useMemo(
    () => getSelectedBlocksMetadata(editorState).get('text-align'),
    [editorState]
  );

  const addBlockAlignmentData = (value: TextAlignment) => {
    onChange(
      setBlockData(editorState, {
        'text-align': currentTextAlignment !== value ? value : undefined,
      })
    );
  };

  return (
    <ToolGroup aria-label="rdw-textalign-control">
      <ToolButton
        aria-pressed={currentTextAlignment === TextAlignment.Left}
        onClick={() => addBlockAlignmentData(TextAlignment.Left)}
        title={intl.formatMessage({ id: 'wysiwygEditor.textAlign.left' })}
      >
        <AlignLeftIcon />
      </ToolButton>

      <ToolButton
        aria-pressed={currentTextAlignment === TextAlignment.Center}
        onClick={() => addBlockAlignmentData(TextAlignment.Center)}
        title={intl.formatMessage({ id: 'wysiwygEditor.textAlign.center' })}
      >
        <AlignCenterIcon />
      </ToolButton>

      <ToolButton
        aria-pressed={currentTextAlignment === TextAlignment.Right}
        onClick={() => addBlockAlignmentData(TextAlignment.Right)}
        title={intl.formatMessage({ id: 'wysiwygEditor.textAlign.right' })}
      >
        <AlignRightIcon />
      </ToolButton>

      <ToolButton
        aria-pressed={currentTextAlignment === TextAlignment.Justify}
        onClick={() => addBlockAlignmentData(TextAlignment.Justify)}
        title={intl.formatMessage({ id: 'wysiwygEditor.textAlign.justify' })}
      >
        <AlignJustifyIcon />
      </ToolButton>
    </ToolGroup>
  );
}
