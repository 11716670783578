import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function SuperscriptIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="-0.5 1 18 18" {...props}>
      <path d="M7.3 10.16L11.88 15H9.05l-3.2-3.54L2.76 15H0l4.52-4.83L.22 5.6h2.77l3 3.31L9 5.61h2.65L7.3 10.15zm6.24-3.26l1.88-1.31c.64-.42 1.06-.8 1.26-1.16.2-.35.3-.74.3-1.15 0-.66-.23-1.2-.7-1.62a2.75 2.75 0 00-1.87-.61c-.75 0-1.34.2-1.79.62-.45.42-.67 1.04-.67 1.88h1.44c0-.5.1-.84.28-1.04.19-.19.44-.28.77-.28s.58.1.78.29a1 1 0 01.28.73c0 .29-.09.55-.26.78s-.67.63-1.48 1.18c-.7.48-1.63.93-1.91 1.35l.01 1.57H17V6.9h-3.46z" />
    </SvgIcon>
  );
}
