import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { EditorState } from 'draft-js';

import { RedoIcon } from '../../icons/RedoIcon';
import { UndoIcon } from '../../icons/UndoIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function History({ editorState, onChange }: Props) {
  const intl = useIntl();

  const handleUndo = useCallback(() => {
    onChange(EditorState.moveFocusToEnd(EditorState.undo(editorState)));
  }, [editorState, onChange]);

  const handleRedo = useCallback(() => {
    onChange(EditorState.moveSelectionToEnd(EditorState.redo(editorState)));
  }, [editorState, onChange]);

  return (
    <ToolGroup aria-label="rdw-history-control">
      <ToolButton
        onClick={handleUndo}
        disabled={editorState.getUndoStack().size === 0}
        title={intl.formatMessage({ id: 'wysiwygEditor.history.undo' })}
      >
        <UndoIcon />
      </ToolButton>

      <ToolButton
        onClick={handleRedo}
        disabled={editorState.getRedoStack().size === 0}
        title={intl.formatMessage({ id: 'wysiwygEditor.history.redo' })}
      >
        <RedoIcon />
      </ToolButton>
    </ToolGroup>
  );
}
