import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditorState, Modifier } from 'draft-js';

import { ToolGroup } from '../common/ToolGroup';
import useToolModal from '../common/useToolModal';
import { CaretClose, CaretOpen, SelectWrapper, StyledSelectedText } from '../FontFamily/Dropdown';
import { AddSymbolModal } from './AddSymbolModal';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function Symbols({ onChange, editorState }: Props) {
  const [expanded, setExpanded] = useState(false);
  const intl = useIntl();
  const referenceElementRef = useRef(null);
  const popperElementRef = useRef(null);
  const { styles, attributes, isModalOpen, openModal, closeModal } = useToolModal(
    referenceElementRef,
    popperElementRef,
    undefined,
    () => setExpanded(false),
    [referenceElementRef, popperElementRef]
  );

  const addSymbol = useCallback(
    (currency: string) => {
      const selectionState = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      const contentStateWithNewEntity = contentState.createEntity('SYMBOL', 'SEGMENTED', currency);
      const entityKey = contentStateWithNewEntity.getLastCreatedEntityKey();

      const newContentState = selectionState.isCollapsed()
        ? Modifier.insertText(contentState, selectionState, currency, undefined, entityKey)
        : Modifier.replaceText(contentState, selectionState, currency, undefined, entityKey);

      onChange(EditorState.push(editorState, newContentState, 'insert-fragment'));
    },
    [onChange, editorState]
  );

  const toggleModal = () => {
    if (expanded) {
      closeModal();
    } else {
      openModal();
    }
    setExpanded(!expanded);
  };

  const closeHandler = () => {
    setExpanded(false);
    closeModal();
  };

  return (
    <ToolGroup aria-label="rdw-symbo-control" ref={referenceElementRef}>
      <SelectWrapper aria-expanded={isModalOpen} arial-label="rdw-dropdown">
        <StyledSelectedText onClick={toggleModal}>
          {intl.formatMessage({ id: 'wysiwygEditor.symbols.title' })}
          {isModalOpen ? <CaretClose /> : <CaretOpen />}
        </StyledSelectedText>
      </SelectWrapper>

      <AddSymbolModal
        ref={popperElementRef}
        style={styles.popper}
        {...attributes.popper}
        isOpen={isModalOpen}
        onSubmit={addSymbol}
        onCancel={closeHandler}
      />
    </ToolGroup>
  );
}
