import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ContentState, EditorState, Modifier } from 'draft-js';
import styled from 'styled-components';

import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';

const CurrencyIcon = styled.div`
  font-weight: semi-bold;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

enum CurrencyType {
  Dollar = '$',
  Yen = '¥',
  Euro = '€',
  Pound = '£',
}

export const insertCharacter = (editorState: EditorState, contentState: ContentState) => {
  const newState = EditorState.push(editorState, contentState, 'insert-fragment');
  return EditorState.forceSelection(newState, contentState.getSelectionAfter());
};

export function Currency({ onChange, editorState }: Props) {
  const intl = useIntl();

  const addCurrency = useCallback(
    (currency: CurrencyType) => {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const currentInlineStyle = editorState.getCurrentInlineStyle();
      const newContentState = selection.isCollapsed()
        ? Modifier.insertText(contentState, selection, currency, currentInlineStyle)
        : Modifier.replaceText(contentState, selection, currency, currentInlineStyle);

      onChange(insertCharacter(editorState, newContentState));
    },
    [editorState, onChange]
  );

  const options = [
    { name: 'pound', icon: CurrencyType.Pound },
    { name: 'yen', icon: CurrencyType.Yen },
    { name: 'euro', icon: CurrencyType.Euro },
    { name: 'dollar', icon: CurrencyType.Dollar },
  ];

  return (
    <ToolGroup aria-label="rdw-currency-control">
      {options.map(currency => {
        return (
          <ToolButton
            key={currency.name}
            onClick={() => addCurrency(currency.icon)}
            title={intl.formatMessage({ id: `wysiwygEditor.currency.${currency.name}` })}
          >
            <CurrencyIcon>{currency.icon}</CurrencyIcon>
          </ToolButton>
        );
      })}
    </ToolGroup>
  );
}
