import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';

export enum ButtonSize {
  Small,
  Medium,
}

export const ToolButton = styled.button.attrs({
  type: 'button',
})<{ buttonSize?: ButtonSize }>`
  ${flexCenter()};
  box-sizing: content-box;
  @media ${breakpoints.lgUp} {
    padding: ${rem(18)} ${rem(30)} ${rem(28)};
  }
  ${({ buttonSize = ButtonSize.Medium }) => {
    if (buttonSize === ButtonSize.Medium) {
      return css`
        padding: ${rem(8)} ${rem(8)};
        height: ${rem(10)};
        width: ${rem(10)};

        @media ${breakpoints.xsUp} {
          padding: ${rem(10)} ${rem(10)};
          height: ${rem(10)};
          width: ${rem(10)};
        }

        @media ${breakpoints.smUp} {
          padding: ${rem(10)} ${rem(11)};
          height: ${rem(12)};
          width: ${rem(12)};
        }
      `;
    }

    return css`
      padding: ${rem(8)} ${rem(9)};
      height: ${rem(12)};
      width: ${rem(12)};
    `;
  }};
  margin: 0 ${rem(2)} ${rem(4)};
  @media ${breakpoints.smUp} {
    margin: 0 ${rem(3)} ${rem(5)};
  }
  border-radius: ${rem(2)};
  border: 1px solid ${({ theme }) => theme.colors.greyLighten};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  outline: none;
  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.grey};
  }

  &:active,
  &[aria-pressed='true'] {
    box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.grey} inset;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  svg {
    height: 100%;
  }
`;
