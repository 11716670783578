import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

import GenericButton from 'components/GenericButton/GenericButton';

import { ToolModal, ToolModalButtons } from '../common/ToolModal';
import { currencies } from './symbols';
import SymbolsContainer from './SymbolsContainer';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  isOpen: boolean;
  onSubmit: (symbol: string) => void;
  onCancel: () => void;
}

export const AddSymbolModal = React.forwardRef<HTMLDivElement, Props>(
  ({ isOpen, onSubmit, onCancel, ...rest }, ref) => {
    const clickHandler = (currency: string) => {
      onSubmit(currency);
      onCancel();
    };

    return ReactDOM.createPortal(
      <ToolModal role="dialog" aria-hidden={!isOpen} {...rest} ref={ref}>
        <SymbolsContainer symbols={currencies} onClick={clickHandler} />

        <ToolModalButtons>
          <GenericButton type="button" onClick={onCancel} isTransparent hasNoWidth isMedium>
            <FormattedMessage id="wysiwygEditor.generic.cancel" />
          </GenericButton>
        </ToolModalButtons>
      </ToolModal>,
      document.body
    );
  }
);
