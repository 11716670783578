export const currencies: Record<string, string> = {
  lek: 'Lek',
  afghani: '؋',
  peso: '₱',
  guilder: 'ƒ',
  dollar: '$',
  manat: '₼',
  ruble: '₽',
  boliviano: '$b',
  'convertible marka': 'KM',
  pula: 'P',
  lev: 'лв',
  riel: '៛',
  'yuan renminbi': '¥',
  kuna: 'kn',
  koruna: 'Kč',
  krone: 'kr',
  pound: '£',
  kroon: 'kr',
  euro: '€',
  lari: '₾',
  cedis: '¢',
  quetzal: 'Q',
  lempira: 'L',
  forint: 'Ft',
  krona: 'kr',
  rupee: '₨',
  rupiah: 'Rp',
  rial: '﷼',
  shekel: '₪',
  yen: '¥',
  tenge: 'лв',
  won: '₩',
  som: 'лв',
  kip: '₭',
  lat: 'Ls',
  litas: 'Lt',
  denar: 'ден',
  ringgit: 'RM',
  tughrik: '₮',
  metical: 'MT',
  'antilles guilder': 'ƒ',
  cordoba: 'C$',
  naira: '₦',
  balboa: 'B/.',
  guarani: 'Gs',
  'nuevo sol': 'S/.',
  zloty: 'zł',
  riyal: '﷼',
  'new leu': 'lei',
  dinar: 'Дин.',
  shilling: 'S',
  rand: 'R',
  franc: 'CHF',
  baht: '฿',
  lira: '₺',
  hryvna: '₴',
  'bolivar fuerte': 'Bs',
  dong: '₫',
  INR: '₹',
};
