import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function AlignRightIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M14.618 14.887H6.45a.326.326 0 010-.652h8.167a.326.326 0 010 .652zm0-4.725H.326a.326.326 0 010-.653h14.292a.326.326 0 010 .653zm0-4.727H6.45a.326.326 0 010-.652h8.167a.326.326 0 010 .652zm0-4.726H.326a.326.326 0 010-.652h14.292a.326.326 0 010 .652z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
