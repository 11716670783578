import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AtomicBlockUtils, EditorState } from 'draft-js';

import { ImageIcon } from '../../icons/ImageIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';
import useToolModal from '../common/useToolModal';
import { AddImageModal, Props as AddImageModalProps } from './AddImageModal';

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
}

export function Image({ onChange, editorState }: Props) {
  const intl = useIntl();
  const referenceElementRef = useRef(null);
  const popperElementRef = useRef(null);
  const { styles, attributes, closeModal, toggleModal, isModalOpen } = useToolModal(
    referenceElementRef,
    popperElementRef
  );

  const addImage = useCallback<AddImageModalProps['onSubmit']>(
    data => {
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('IMAGE', 'MUTABLE', data)
        .getLastCreatedEntityKey();

      onChange(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' '));

      closeModal();
    },
    [closeModal, editorState, onChange]
  );

  return (
    <ToolGroup aria-label="rdw-image-control" ref={referenceElementRef}>
      <ToolButton
        onClick={toggleModal}
        aria-haspopup="dialog"
        aria-pressed={isModalOpen}
        title={intl.formatMessage({ id: 'wysiwygEditor.image.title' })}
      >
        <ImageIcon />
      </ToolButton>

      <AddImageModal
        ref={popperElementRef}
        style={styles.popper}
        {...attributes.popper}
        isOpen={isModalOpen}
        onSubmit={addImage}
        onCancel={closeModal}
      />
    </ToolGroup>
  );
}
