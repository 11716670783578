export enum InlineStyle {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
  Strikethrough = 'STRIKETHROUGH',
  Code = 'CODE',
  Superscript = 'SUPERSCRIPT',
  Subscript = 'SUBSCRIPT',
}

export default [
  InlineStyle.Bold,
  InlineStyle.Code,
  InlineStyle.Italic,
  InlineStyle.Strikethrough,
  InlineStyle.Subscript,
  InlineStyle.Superscript,
  InlineStyle.Underline,
];
