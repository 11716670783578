import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function UnderlineIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="1.5 2 12 12" {...props}>
      <path d="M6.045 2v.992L4.785 3v5.172c0 .859.243 1.512.727 1.957s1.124.668 1.918.668c.836 0 1.509-.221 2.019-.664.511-.442.766-1.096.766-1.961V3l-1.26-.008V2H13v.992L11.739 3v5.172c0 1.234-.398 2.181-1.195 2.84-.797.659-1.835.988-3.114.988-1.242 0-2.248-.329-3.017-.988-.769-.659-1.152-1.605-1.152-2.84V3L2 2.992V2h4.045zM2 13h11v1H2z" />
    </SvgIcon>
  );
}
