import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function ItalicIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="2 2 12 12" {...props}>
      <path d="M7 3V2h4v1H9.753l-3 10H8v1H4v-1h1.247l3-10H7z" />
    </SvgIcon>
  );
}
