import React, { useCallback } from 'react';
import { EditorState } from 'draft-js';
import styled from 'styled-components';

import { TooltipPlacement } from 'components/GenericTooltip';

import { BlockAlignment } from '../../constants/blockAlignment';
import { ToolTooltip } from '../../controls/common/ToolTooltip';
import type { RendererProps } from '../types';
import { EditImageModal, Props as EditImageModalProps } from './EditImageModal';

const Wrapper = styled.span`
  display: flex;

  img {
    &[data-alignment='${/* sc-sel */ BlockAlignment.Left}'] {
      margin-right: auto;
    }

    &[data-alignment='${/* sc-sel */ BlockAlignment.Center}'] {
      margin: auto;
    }

    &[data-alignment='${/* sc-sel */ BlockAlignment.Right}'] {
      margin-left: auto;
    }
  }
`;

export interface ImageBlockProps {
  readOnly: boolean;
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  onToggleEditorReadOnlyState: (readOnlyValue: boolean) => void;
}

export const ImageComponent = ({
  block,
  contentState,
  blockProps: { editorState, onChange, onToggleEditorReadOnlyState, readOnly },
}: RendererProps<ImageBlockProps>) => {
  const {
    src,
    width,
    height,
    'data-alignment': alignment,
  } = contentState.getEntity(block.getEntityAt(0)).getData();

  const mergeEntityData = useCallback(
    (data: Record<string, unknown>) => {
      const newContentState = contentState.mergeEntityData(block.getEntityAt(0), data);

      onChange(EditorState.push(editorState, newContentState, 'change-block-data'));
    },
    [block, contentState, editorState, onChange]
  );

  const setEntityAlignment = useCallback<EditImageModalProps['onChangeAlignment']>(
    newAlignment => mergeEntityData({ 'data-alignment': newAlignment }),
    [mergeEntityData]
  );

  const setEntityDimensions = useCallback<EditImageModalProps['onSubmit']>(
    ({ width: newWidth, height: newHeight }) =>
      mergeEntityData({ width: newWidth, height: newHeight }),
    [mergeEntityData]
  );

  return (
    <Wrapper>
      <ToolTooltip
        disabled={readOnly}
        placement={TooltipPlacement.Bottom}
        onShow={() => onToggleEditorReadOnlyState(true)}
        onHide={() => onToggleEditorReadOnlyState(false)}
        appendTo={() => document.body}
        interactive
        render={(attrs, content, instance) => (
          <EditImageModal
            {...attrs}
            isOpen
            initialValues={{ width, height }}
            onSubmit={setEntityDimensions}
            onCancel={() => instance?.hide()}
            currentAlignment={alignment}
            onChangeAlignment={setEntityAlignment}
          />
        )}
      >
        <img
          src={src}
          alt=""
          style={{ width: `${width}px`, height: `${height}px` }}
          data-alignment={alignment || BlockAlignment.Left}
        />
      </ToolTooltip>
    </Wrapper>
  );
};
