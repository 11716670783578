export enum FontFamily {
  Lato = 'Lato',
  Montserrat = 'Montserrat',
  OpenSans = 'OpenSans',
  Poppins = 'Poppins',
  Roboto = 'Roboto',
}

export default [
  FontFamily.Lato,
  FontFamily.Montserrat,
  FontFamily.OpenSans,
  FontFamily.Poppins,
  FontFamily.Roboto,
];
