import React from 'react';
import type { ContentState, DraftDecorator } from 'draft-js';
import styled from 'styled-components';

import { TooltipPlacement } from 'components/GenericTooltip';

import { ToolTooltip } from '../../controls/common/ToolTooltip';
import { OpenLinkIcon } from '../../icons/OpenLinkIcon';

const findLinkEntities: DraftDecorator['strategy'] = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Anchor = styled.a`
  text-decoration: underline;
`;

const PopOver = styled.button`
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  svg {
    height: 1em;
    width: 1em;
  }
`;

interface Props {
  children?: React.ReactNode;
  contentState: ContentState;
  entityKey: string | null | undefined;
}

function Link({ entityKey, children, contentState }: Props) {
  if (!entityKey) {
    return null;
  }

  const { url, target } = contentState.getEntity(entityKey).getData();

  const openLink = () => {
    window.open(url, 'blank');
  };

  return (
    <ToolTooltip
      placement={TooltipPlacement.RightStart}
      appendTo={() => document.body}
      interactive
      render={attrs => (
        <PopOver {...attrs} onClick={openLink} type="button">
          <OpenLinkIcon />
        </PopOver>
      )}
    >
      <Anchor href={url} target={target}>
        {children}
      </Anchor>
    </ToolTooltip>
  );
}

export const linkDecorator: DraftDecorator = {
  strategy: findLinkEntities,
  component: Link,
};
