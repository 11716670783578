import React, { HTMLAttributes } from 'react';
import { Form, FormProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import { flexCenter } from 'helpers/styles/mixins';
import GenericButton from 'components/GenericButton/GenericButton';
import { FieldSpacer } from 'components/Layout';

import { BlockAlignment } from '../../constants/blockAlignment';
import { DimensionsFields } from '../../controls/common/DimensionsFields';
import { ButtonSize, ToolButton } from '../../controls/common/ToolButton';
import { FloatingToolModal, ToolModalButtons } from '../../controls/common/ToolModal';
import { AlignCenterIcon } from '../../icons/AlignCenterIcon';
import { AlignLeftIcon } from '../../icons/AlignLeftIcon';
import { AlignRightIcon } from '../../icons/AlignRightIcon';

const StyledModal = styled(FloatingToolModal)`
  max-width: ${rem(240)};
`;

const AlignmentOptions = styled.div`
  ${flexCenter()};
`;

interface Values {
  height: number;
  width: number;
}

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  isOpen: boolean;
  initialValues: FormProps<Values>['initialValues'];
  onSubmit: FormProps<Values>['onSubmit'];
  onChangeAlignment: (newAlignment: BlockAlignment) => void;
  currentAlignment: BlockAlignment;
  onCancel: () => void;
}

export const EditImageModal = React.forwardRef<HTMLDivElement, Props>(
  (
    { isOpen, initialValues, onSubmit, onChangeAlignment, currentAlignment, onCancel, ...rest },
    ref
  ) => {
    return (
      <StyledModal
        role="dialog"
        aria-hidden={!isOpen}
        {...rest}
        ref={ref}
        onClick={e => e.stopPropagation()}
      >
        <AlignmentOptions>
          <ToolButton
            buttonSize={ButtonSize.Small}
            aria-pressed={!currentAlignment || currentAlignment === BlockAlignment.Left}
            onClick={() => onChangeAlignment(BlockAlignment.Left)}
          >
            <AlignLeftIcon />
          </ToolButton>

          <ToolButton
            buttonSize={ButtonSize.Small}
            aria-pressed={currentAlignment === BlockAlignment.Center}
            onClick={() => onChangeAlignment(BlockAlignment.Center)}
          >
            <AlignCenterIcon />
          </ToolButton>

          <ToolButton
            buttonSize={ButtonSize.Small}
            aria-pressed={currentAlignment === BlockAlignment.Right}
            onClick={() => onChangeAlignment(BlockAlignment.Right)}
          >
            <AlignRightIcon />
          </ToolButton>
        </AlignmentOptions>

        <FieldSpacer />

        <Form<Values>
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <DimensionsFields />

              <ToolModalButtons>
                <GenericButton type="submit" disabled={submitting} hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.save" />
                </GenericButton>

                <GenericButton type="button" onClick={onCancel} isTransparent hasNoWidth isMedium>
                  <FormattedMessage id="wysiwygEditor.generic.cancel" />
                </GenericButton>
              </ToolModalButtons>
            </form>
          )}
        />
      </StyledModal>
    );
  }
);
