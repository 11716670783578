import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function LinkIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M13.967.95A3.226 3.226 0 0011.67.002c-.87 0-1.686.337-2.297.948L7.105 3.218A3.247 3.247 0 006.24 6.24a3.225 3.225 0 00-3.022.865L.95 9.373a3.253 3.253 0 000 4.594 3.226 3.226 0 002.297.948c.87 0 1.686-.336 2.298-.948L7.812 11.7a3.247 3.247 0 00.865-3.023 3.225 3.225 0 003.022-.865l2.268-2.267a3.252 3.252 0 000-4.595zM7.105 10.993L4.837 13.26a2.233 2.233 0 01-1.59.655 2.233 2.233 0 01-1.59-.655 2.252 2.252 0 010-3.18l2.268-2.268a2.232 2.232 0 011.59-.655c.43 0 .841.12 1.195.343L4.772 9.438a.5.5 0 10.707.707l1.939-1.938c.545.868.442 2.03-.313 2.785zm6.155-6.155l-2.268 2.267a2.233 2.233 0 01-1.59.655c-.431 0-.841-.12-1.195-.343l1.938-1.938a.5.5 0 10-.707-.707L7.499 6.71a2.252 2.252 0 01.313-2.785l2.267-2.268a2.233 2.233 0 011.59-.655 2.233 2.233 0 012.246 2.245c0 .603-.232 1.168-.655 1.59z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
