import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function OutdentIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 16 14" {...props}>
      <path
        d="M5.4 3.2h10.57v1.19H5.4zM.04 0h15.93v1.2H.04zm0 12.77h15.93v1.2H.04zm5.36-3.2h10.57v1.2H5.4zm0-3.19h10.57v1.2H5.4zM2.19 4.5L0 7l2.19 2.48z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
