import React from 'react';

import SvgIcon from 'components/Icons/SvgIcon';

export function AlignJustifyIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <path
        d="M14.62 14.888H.325a.326.326 0 010-.652H14.62a.326.326 0 010 .652zm0-4.726H.325a.326.326 0 010-.652H14.62a.326.326 0 010 .652zm0-4.726H.325a.326.326 0 010-.652H14.62a.326.326 0 010 .652zm0-4.726H.325a.326.326 0 010-.653H14.62a.326.326 0 010 .653z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
