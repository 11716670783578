import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { getSelectionInlineStyle } from 'draftjs-utils';

import { InlineStyle } from '../../constants/inlineStyles';
import { BoldIcon } from '../../icons/BoldIcon';
import { ItalicIcon } from '../../icons/ItalicIcon';
import { MonospaceIcon } from '../../icons/MonospaceIcon';
import { StrikethroughIcon } from '../../icons/StrikethroughIcon';
import { SubscriptIcon } from '../../icons/SubscriptIcon';
import { SuperscriptIcon } from '../../icons/SuperscriptIcon';
import { UnderlineIcon } from '../../icons/UnderlineIcon';
import { ToolButton } from '../common/ToolButton';
import { ToolGroup } from '../common/ToolGroup';

function applyInlineStyle(editorState: EditorState, style: InlineStyle): EditorState {
  const newState = RichUtils.toggleInlineStyle(editorState, style);

  if (style === InlineStyle.Subscript || style === InlineStyle.Superscript) {
    return EditorState.push(
      newState,
      Modifier.removeInlineStyle(
        newState.getCurrentContent(),
        newState.getSelection(),
        style === InlineStyle.Subscript ? InlineStyle.Superscript : InlineStyle.Subscript
      ),
      'change-inline-style'
    );
  }

  return newState;
}

export interface InlineToolOptions {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  code?: boolean;
  superscript?: boolean;
  subscript?: boolean;
}

interface Props {
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
  options: InlineToolOptions;
}

export function Inline({ onChange, editorState, options }: Props) {
  const intl = useIntl();

  const { bold, italic, underline, strikethrough, code, superscript, subscript } = options;

  const currentStyles = useMemo(() => getSelectionInlineStyle(editorState), [editorState]);

  const toggleInlineStyle = (newStyle: InlineStyle) => {
    onChange(applyInlineStyle(editorState, newStyle));
  };

  return (
    <ToolGroup aria-label="rdw-inline-control">
      {bold && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Bold)}
          aria-pressed={currentStyles[InlineStyle.Bold]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.bold' })}
        >
          <BoldIcon />
        </ToolButton>
      )}

      {italic && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Italic)}
          aria-pressed={currentStyles[InlineStyle.Italic]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.italic' })}
        >
          <ItalicIcon />
        </ToolButton>
      )}

      {underline && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Underline)}
          aria-pressed={currentStyles[InlineStyle.Underline]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.underline' })}
        >
          <UnderlineIcon />
        </ToolButton>
      )}

      {strikethrough && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Strikethrough)}
          aria-pressed={currentStyles[InlineStyle.Strikethrough]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.strikethrough' })}
        >
          <StrikethroughIcon />
        </ToolButton>
      )}

      {code && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Code)}
          aria-pressed={currentStyles[InlineStyle.Code]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.code' })}
        >
          <MonospaceIcon />
        </ToolButton>
      )}

      {superscript && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Superscript)}
          aria-pressed={currentStyles[InlineStyle.Superscript]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.superscript' })}
        >
          <SuperscriptIcon />
        </ToolButton>
      )}

      {subscript && (
        <ToolButton
          onClick={() => toggleInlineStyle(InlineStyle.Subscript)}
          aria-pressed={currentStyles[InlineStyle.Subscript]}
          title={intl.formatMessage({ id: 'wysiwygEditor.inline.subscript' })}
        >
          <SubscriptIcon />
        </ToolButton>
      )}
    </ToolGroup>
  );
}
