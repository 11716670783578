import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';

import { misc } from 'helpers/styles/constants';
import { useGenericModalContext } from 'components/GenericModal/GenericModalContext';

export function ToolTooltip(props: TippyProps) {
  const { isInsideModal } = useGenericModalContext();

  return <Tippy zIndex={isInsideModal ? misc.modalZIndex + 1 : misc.zIndex1} {...props} />;
}
